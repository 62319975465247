
import { defineComponent, ref, computed } from '@vue/composition-api';
import { Field } from '../index';

interface OrganizerSetupOKR {
  airtableLink: string;
}
interface DataField extends Field {
  value: never;
  setup: OrganizerSetupOKR;
}
export default defineComponent({
  name: 'MGetTeacherPlan',
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Get Teacher Plan';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-get-teacher-plan';
          }
          return false;
        }
      );
      return Fdindx;
    });

    const fieldValue = computed<DataField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const userIsEmployer = ref(_props.userType === 'organizer');

    const organizerData = computed<OrganizerSetupOKR>({
      get: () => fieldValue.value.setup,
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });

    return {
      fieldValue,
      organizerData,
      userIsEmployer
    };
  }
});
